﻿$mainFont: 'Lato', sans-serif;
$altFont: 'Montserrat', sans-serif;

$baseFontSize: 16px;
$baseText: #444;
$baseInverse: #fff;
$accentBase: #f57f32;
$accentLight: #f3a36f;
$highlight: #eee;


/* Borrowed from Bootstrap */

// Extra small screen / phone
$screen-xs-min: 480px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1200px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);