@import "inc/_variables.scss";
@import "inc/_vesper.scss";
@import "inc/hamburgers/hamburgers.scss";

body
{
    text-align: center;
    font-weight: 300;
}

h1, h3
{
    font-size: 28px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 2.5em;
    margin-bottom: 1em;
    color: $accentBase;

    > small
    {
        margin-top: 0.5em;
        font-size: 0.5em;
        letter-spacing: 0.1em;
        font-weight: bold;
        display: block;
        color: $baseText;
    }
}

h2
{
    font-size: 20px;
    line-height: 1.75;
    margin-bottom: 1em;
    font-weight: 300;
}

.heading-bordered
{
    position: relative;
    clear: both;

    > span
    {
        display: inline-block;
        background-color: $baseInverse;
        z-index: 1;
        transform: translateZ(0);
        padding: 0 1em;
    }

    &:before
    {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 1px solid $highlight;
        left: 0;
        top: 0.5em;
        z-index: 0;
    }
}

p
{
    margin-bottom: 2em;
}

a
{
    font-weight: normal;
    border-bottom: 1px solid $accentBase;

    &:focus,
    &:hover
    {
        text-decoration: none;
        color: darken($accentBase, 15%);
        border-bottom-color: darken($accentBase, 15%);
    }
}

li
{
    list-style-position: inside;
}

@media(min-width: $screen-md-min)
{
    .desktop-right
    {
        text-align: right;
    }
}

.desktop-pull-right
{
    @media(max-width: $screen-sm-max)
    {
        margin-bottom: 1em;
    }

    @media(min-width: $screen-md-min)
    {
        float: right;
        text-align: right;
    }
}


.tablet-pull-right
{
    @media(max-width: $screen-xs-max)
    {
        margin-bottom: 1em;
    }

    @media(min-width: $screen-sm-min)
    {
        float: right;
        text-align: right;
    }
}

.form-control
{
    font-size: 14px;
    border-radius: 0;
    border: 1px solid #eee;
    box-shadow: none;
    text-align: center;
    text-align-last: center;
}

.form-horizontal
{
    text-align: left;

    .form-control
    {
        text-align: left;
        text-align-last: left;
    }
}

.btn
{
    width: 100%;
    display: block;
    border-radius: 0;
    border: 3px solid $baseInverse;
    font-family: $altFont;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: convert-to-rem(1.5px);
    padding: 10px 4px;

    &.btn-transparent
    {
        background-color: transparent;

        &:hover,
        &:focus
        {
            background-color: rgba(0,0,0,0.5);
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover
    {
        border: 3px solid $baseInverse;
    }

    &:last-child
    {
        margin-bottom: 0;
    }
}

.btn-icon
{
    position: relative;
    background-color: $highlight;
    color: $baseText;
    padding: 15px 70px 15px 20px;
    border: 0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;

    > .icon
    {
        width: 50px;
        font-size: 18px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        background-color: $accentBase;
        color: $baseInverse;

        > i
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
    }

    &:hover,
    &:active,
    &:focus,
    &:hover:active
    {
        border: 0;
        background-color: $accentBase;
        color: $baseInverse;
    }
}

input[type=checkbox]
{
    visibility: hidden;
    margin-left: 6px;
    display: inline-block;
    font-weight: normal;

    &:before
    {
        content: "\f096";
        font-family: FontAwesome;
        visibility: visible;
        vertical-align: top;
    }

    &:checked:before
    {
        content: "\f046";
    }
}

.bullet-row
{
    padding-left: 0;
}

.text-right-sm
{
    text-align: center;
}

@media(min-width: $screen-sm-min)
{
    .text-right-sm
    {
        text-align: right;
    }
}

.help-block.text-danger
{
    color: #a94442;
}